import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserInfo } from '../models/auth';

const signInReturnUrlPathLocalStorageKey = 'signInReturnUrlPath';
const cognitoAdminsGroupName = 'GreenLightAdmins';

export const navigateToSignInReturnUrlPath = (): void => {
  const signInReturnUrlPath = localStorage.getItem(signInReturnUrlPathLocalStorageKey);
  if (signInReturnUrlPath != null) {
    localStorage.removeItem(signInReturnUrlPathLocalStorageKey);
    window.location.href = signInReturnUrlPath;
  }
};

export const customSignIn = async (): Promise<void> => {
  localStorage.setItem(signInReturnUrlPathLocalStorageKey, window.location.pathname + window.location.search);
  window.location.href = `https://${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/authorize?identity_provider=${process.env.REACT_APP_OAUTH_PROVIDER}&redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN}&response_type=${process.env.REACT_APP_OAUTH_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}`;
};

export type AmplifyBackendError = Error & {
  code: string;
};

export const signInErrorCodeToMessage = (err: AmplifyBackendError): string => {
  if (err.code === 'UserNotConfirmedException') {
    return 'User is not confirmed';
  } else if (err.code === 'NotAuthorizedException') {
    // The error happens when the incorrect password is provided
    return 'Login failed.';
  } else if (err.code === 'UserNotFoundException') {
    // The error happens when the supplied username/email does not exist in the Cognito user pool
    return 'Login failed.';
  } else {
    return 'An error has occurred.';
  }
};

const currentSession = async (): Promise<CognitoUserSession> => {
  try {
    return await Auth.currentSession();
  } catch (error) {
    // perform custom login as the current session is timed out
    await customSignIn();
    return await Auth.currentSession();
  }
};

export const getAccessToken = async (): Promise<string> => {
  return (await currentSession()).getAccessToken().getJwtToken();
};

export const getIdToken = async (): Promise<string> => {
  return (await currentSession()).getIdToken().getJwtToken();
};

export const getUserInfo = async (): Promise<UserInfo> => {
  const idTokenPayload = (await currentSession()).getIdToken().payload;
  return {
    familyName: idTokenPayload.family_name,
    givenName: idTokenPayload.given_name,
    email: idTokenPayload.email,
    username:
      Array.isArray(idTokenPayload.identities) && idTokenPayload.identities.length > 0
        ? idTokenPayload.identities[0].userId
        : ''
  };
};

export const isCurrentUserAdmin = async (): Promise<boolean> => {
  const session = await currentSession();
  const cognitoGroups = session.getAccessToken().payload['cognito:groups'];
  return Array.isArray(cognitoGroups) && cognitoGroups.includes(cognitoAdminsGroupName);
};
