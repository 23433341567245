import { Box, SpaceBetween, Spinner, SpinnerProps, TextContent } from '@awsui/components-react';
import { ReactElement } from 'react';

export type LoadingSpinnerProps = {
  loadingText?: string;
  spinnerSize?: SpinnerProps.Size;
};

export const LoadingSpinner = ({ loadingText, spinnerSize = 'big' }: LoadingSpinnerProps): ReactElement => (
  <Box padding={spinnerSize === 'large' ? 'xxxl' : 'm'} data-testid="loading-page">
    <SpaceBetween alignItems="center" direction={loadingText != null ? 'horizontal' : 'vertical'} size="s">
      <Spinner size={spinnerSize} />
      {loadingText != null && (
        <TextContent>
          <h5>{loadingText}</h5>
        </TextContent>
      )}
    </SpaceBetween>
  </Box>
);
