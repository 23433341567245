import { Box, Container, Grid, Header, SpaceBetween } from '@awsui/components-react';
import { ReactElement } from 'react';
import './styles/maintenance-page.scss';

export const MaintenanceMode = (): ReactElement => {
  const message =
    'Please DO NOT use this environment as its a legacy GreenLight environment. In July 2024, we moved GreenLight to new production environment. Access new production using this link -> https://production.greenlight.security.aws.dev/ Please reach out to GreenLight team via email or slack if you face issues connecting.';
  return (
    <Box margin={{ bottom: 'l' }}>
      <div className="custom-maintenance__header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } }
            ]}>
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-maintenance__category">AWS Security Assurance</span>
            </Box>
            <div className="custom-maintenance__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                RISE GreenLight
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                All the data. One Green light.
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-maintenance__header-sub-title">
                  RISE GreenLight is a global internal service for RISE, Security Assurance.
                </span>
              </Box>
            </div>
          </Grid>
        </Box>
      </div>
      <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
        <Grid gridDefinition={[{ offset: { l: 2, xxs: 1 }, colspan: { l: 6, xxs: 10 } }]}>
          <Container header={<Header variant="h1">Deprecated legacy environment</Header>}>
            <SpaceBetween size="xl">{message}</SpaceBetween>
          </Container>
        </Grid>
      </Box>
    </Box>
  );
};
