import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { App } from './app';

// This decision branch is added to implement client side routing from alternative domain (CloudFront domain) to the new
// environment specific greenlight subdomain. This redirection is required to support greenlight links from MBR and other
// business documents. Note that the redirection only works for the alternative domains.
if (window.location.host === process.env.REACT_APP_ALTERNATIVE_DOMAIN) {
  window.location.href = `${process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN}${window.location.pathname}${window.location.search}`;
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
